<!--
 * @Author: your name
 * @Date: 2021-02-26 09:31:16
 * @LastEditTime: 2021-02-26 15:49:27
 * @LastEditors: Please set LastEditors
 * @Description: 右侧弹框
 * @FilePath: \sd-vue-admin\src\views\project\performance\attendanceManagement\movement\components\RightDrawer.vue
-->
<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      direction="rtl"
      :append-to-body="true"
      size="20%"
    >
      <div style="width: 90%; height: 50%; padding: 10px; overflow-y: auto">
        <el-tabs v-model="form.type" type="card" @tab-click="handleClick">
          <el-tab-pane label="拜访明细" name="0">
            <el-timeline>
              <el-timeline-item
                v-for="list in routerlist"
                :key="list.id"
                :timestamp="list.join_time"
                :color="'#00ff37'"
                placement="top"
              >
                <el-card>
                  <h4>
                    {{ list.cust_name }}
                  </h4>
                  <p>停留：{{ list.stay_tim }}</p>
                  <p v-for="(item, index) in list.action_list" :key="index">
                    {{ item }}
                  </p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <el-tab-pane label="线路漏店" name="1">
            <el-table stripe :data="tabledata">
              <el-table-column
                label="线路名称"
                align="center"
                prop="line_name"
              ></el-table-column>
              <el-table-column
                label="客户名称"
                align="center"
                prop="cust_name"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import { dateFormat } from '@/utils/Time'
  export default {
    data() {
      return {
        drawer: false,
        url: {
          info: '/taskAdmin/path/line-list',
          visit: '/taskAdmin/path/visit-list',
        },
        routerlist: [],
        form: {
          user_id: '',
          create_at: '',
          type: '0',
        },
        tabledata: [],
      }
    },
    watch: {},
    mounted() {},
    methods: {
      handleClick(tab, event) {
        console.log(tab, event)
        if (tab == 0) {
          this.handlerinfo()
        } else {
          this.handlerinfo1()
        }
      },
      handlerData(id, time) {
        console.log(id, time, '')
        this.form.user_id = id
        this.form.create_at = time
        if (this.form.type == 0) {
          this.handlerinfo()
        } else {
          this.handlerinfo1()
        }
      },
      handlerinfo() {
        this.form.pageSize = -1
        postAction(this.url.visit, this.form)
          .then((res) => {
            console.log(res, '')
            this.routerlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerinfo1() {
        postAction(this.url.info, this.form)
          .then((res) => {
            console.log(res, '')

            this.tabledata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style>
  .color {
    color: red;
  }
  .color2 {
    color: #00ff37;
  }
  .color3 {
    color: #ffbb00;
  }
</style>
