var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "baidu-map",
    {
      staticClass: "map",
      staticStyle: { display: "flex", "flex-direction": "column" },
      attrs: { center: _vm.center, zoom: _vm.zoom, "scroll-wheel-zoom": true },
      on: { ready: _vm.handler, click: _vm.clickEvent },
    },
    [
      _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
      _c("bm-city-list", { attrs: { anchor: "BMAP_ANCHOR_TOP_LEFT" } }),
      _c("bm-geolocation", {
        attrs: {
          anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
          "show-address-bar": true,
          "auto-location": true,
        },
        on: { locationSuccess: _vm.getLoctionSuccess },
      }),
      _c("bm-view", {
        staticClass: "bm",
        staticStyle: { flex: "1", width: "100%", height: "100px" },
      }),
      _c(
        "div",
        { staticClass: "ditu" },
        [
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-caret-left" },
            on: { click: _vm.handlerleft },
          }),
        ],
        1
      ),
      _c("left-drawer", { ref: "left" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }