<!--
 * @Author: 候怀烨
 * @Date: 2021-02-24 14:33:31
 * @LastEditTime: 2021-02-26 14:49:58
 * @LastEditors: Please set LastEditors
 * @Description: 左侧抽屉
 * @FilePath: \sd-vue-admin\src\views\project\performance\attendanceManagement\movement\components\LeftDrawer.vue
-->
<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      direction="ltr"
      size="25%"
    >
      <div style="height: 100%">
        <el-calendar v-model="value" @change="handlerchange"></el-calendar>
        <h3>员工</h3>
        <div style="height: 40%; margin-bottom: 20px; overflow-y: auto">
          <div
            v-for="(item, index) in userlist"
            :key="index"
            :class="bgid == item.user_id ? 'bgout' : 'bgto'"
            style="margin: 20px 20px"
            @click="handleruser(item.user_id)"
          >
            <el-row style="padding: 10px">
              <el-col :span="6">
                <el-avatar :size="70" src="https://empty" @error="errorHandler">
                  <img
                    src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                  />
                </el-avatar>
              </el-col>
              <el-col :span="18">
                <el-col :span="24" class="col">
                  <span class="font-w">
                    {{ item.user_name }}
                  </span>
                  <div class="fontjs">
                    <span v-for="(list, inde) in item.user_role" :key="inde">
                      {{ list }}
                    </span>
                  </div>
                </el-col>
                <el-col :span="24">
                  <el-row :gutter="10" class="col pading">
                    <el-col :span="12">
                      <div>
                        异常拜访数：
                        <span>{{ item.visit_ab_num }}</span>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div>
                        任务已拜访：
                        <span>{{ item.visit_line_num }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="24">
                  <el-row :gutter="10" class="col pading">
                    <el-col :span="12">
                      拜访客户：
                      <span>{{ item.visit_num }}</span>
                    </el-col>
                    <el-col :span="12">
                      近期未拜访：
                      <span>{{ item.un_visit_line_num }}</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </div>
        <right-drawer ref="right"></right-drawer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import RightDrawer from './RightDrawer.vue'
  import { postAction } from '@/api/Employee'
  import { dateFormat } from '@/utils/Time'
  export default {
    components: {
      RightDrawer,
    },
    data() {
      return {
        drawer: false,
        value: new Date(),
        url: {
          userlist: '/taskAdmin/path/user-list',
        },
        userlist: [],
        bgid: '',
      }
    },
    watch: {
      value(val) {
        console.log(dateFormat(val))
        this.handleruserlist(dateFormat(val))
        this.bgid = ''
      },
    },
    mounted() {
      this.handleruserlist(dateFormat(new Date()))
    },
    methods: {
      handleruserlist(time) {
        postAction(this.url.userlist, { create_at: time })
          .then((res) => {
            console.log(res, '员工列表')
            this.userlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleruser(id) {
        this.bgid = id
        console.log(Object.prototype.toString.call(this.bgid), '')
        this.$refs.right.drawer = true
        this.$refs.right.handlerData(id, dateFormat(this.value))
      },
      handlerchange(e) {
        console.log(e, '')
      },
      errorHandler() {
        return true
      },
    },
  }
</script>

<style>
  .el-calendar-table .el-calendar-day {
    box-sizing: border-box;
    height: 45px;
    padding: 8px;
    text-align: center;
  }
  .el-calendar {
    background-image: linear-gradient(to bottom right, #26a1fd, #2ec7f1);
  }
  .font-w {
    font-size: 20px;
    font-weight: 600;
  }
  .fontjs {
    display: -webkit-box;
    width: 70%;
    margin-left: 20px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .bgto {
    color: #000;
  }
  .bgout {
    color: #ffffff;
    background-image: linear-gradient(to bottom right, #26a1fd, #2ec7f1);
    border-radius: 20px;
  }
  .col {
    display: flex;
    flex-direction: row;
  }
  .pading {
    padding: 10px 0px;
  }
</style>
