<!--
 * @Author: your name
 * @Date: 2021-02-05 16:25:09
 * @LastEditTime: 2021-02-24 14:42:05
 * @LastEditors: Please set LastEditors
 * @Description: 考勤线路
 * @FilePath: \sd-vue-admin\src\views\project\performance\attendanceManagement\movement\index.vue
-->
<template>
  <baidu-map
    class="map"
    style="display: flex; flex-direction: column"
    :center="center"
    :zoom="zoom"
    :scroll-wheel-zoom="true"
    @ready="handler"
    @click="clickEvent"
  >
    <!--    ak="FzAhPfin2UmY2ZwHiqmGAl2XUlfSxUkY"-->
    <!-- 地图控件位置 -->
    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    <!-- 城市列表 -->
    <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
    <!-- 定位当前位置 -->
    <bm-geolocation
      anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
      :show-address-bar="true"
      :auto-location="true"
      @locationSuccess="getLoctionSuccess"
    ></bm-geolocation>
    <!-- 地图容器 -->
    <bm-view class="bm" style="flex: 1; width: 100%; height: 100px"></bm-view>
    <div class="ditu">
      <el-button
        type="primary"
        icon="el-icon-caret-left"
        @click="handlerleft"
      ></el-button>
    </div>
    <left-drawer ref="left"></left-drawer>
  </baidu-map>
</template>
<script>
  import {
    BaiduMap,
    BmNavigation,
    BmView,
    BmGeolocation,
    BmCityList,
  } from 'vue-baidu-map'
  import LeftDrawer from './components/LeftDrawer.vue'
  import avatar from '@/assets/map_marker_check.png'
  export default {
    components: {
      LeftDrawer,
    },
    data() {
      return {
        center: { lng: 114.52208184, lat: 38.04895831 },
        zoom: 13,
        avatar: avatar,
      }
    },
    methods: {
      handlerleft() {
        this.$refs.left.drawer = true
      },
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      //点击地图监听
      clickEvent(e) {
        var that = this
        map.clearOverlays()

        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(e.point.lng, e.point.lat),
          {
            icon: Icon_0,
          }
        )
        map.addOverlay(myMarker)
        //用所定位的经纬度查找所在地省市街道等信息
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var gc = new BMap.Geocoder()
        let _this = this
        gc.getLocation(point, function (rs) {
          console.log(rs)
          console.log(rs.addressComponents.city)
          var addComp = rs.addressComponents
        })
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        this.form.longitude = point.point.lng
        this.form.latitude = point.point.lat
      },
    },
  }
</script>
<style scoped>
  .bm {
    position: relative;
  }
  .ditu {
    position: absolute;
    top: 40%;
    left: 0px;
  }
</style>
